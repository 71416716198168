/* *,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
} */

/* a,
a:visited,
a:active {
  color: #40c756;
  text-decoration: none;
} */

/* *,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
} */

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-heading);
  font-feature-settings: 'salt' 1;
  color: var(--color-black);
  line-height: var(--line-height-heading);
  font-weight: 600;
  margin: 1rem 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}

h4 {
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  color: #888;
  font-weight: 400;
} */
/* -- Buttons -- */
/* 
.btn {
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
  -ms-flex-item-align: center;
  align-self: center;
  background-color: #e9ebeb;
  border: 0;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #0e1e24;
  display: inline-block;
  line-height: 20px;
  min-width: 36px;
  padding: 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  width: auto;
  cursor: pointer;
}

.btn.btnprimary {
  background-color: #40c756;
  color: #fff;
}

.btn.btndark {
  background-color: #40c778;
  color: #fff;
} */

/* Padding X-Axis (Left Right) */
.px2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

/*  */
.layoutMargin {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Padding X-Axis (Left Right) */
.layout {
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 2rem;
  margin-bottom: 10rem;
}

.layoutShowcase {
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.layoutBg {
  padding-left: 4rem;
  padding-right: 4rem;
  margin-top: 2rem;
  margin-bottom: 10rem;
  background: rgb(255, 165, 5);
  background: linear-gradient(
    90deg,
    rgba(255, 165, 5, 1) 58%,
    rgba(255, 251, 5, 1) 100%
  );
}

.file-exists {
  text-align: left;
  color: red;
}

.drag-drop-zone {
  padding: 2rem;
  text-align: center;
  background: #07f;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 10px #c0c0c0;
}

.drag-drop-zone p {
  color: #fff;
}

.drag-drop-zone.inside-drag-area {
  opacity: 0.7;
}

.dropped-files li {
  color: #07f;
  padding: 3px;
  text-align: left;
  font-weight: bold;
}

.layoutProgress {
  max-width: 300px;
  margin: auto;
}
